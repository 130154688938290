import React, { Component } from "react";
import ContactForm from "./ContactForm";

import about from "../../assets/images/about/about-6.jpg";
import logo_dark_petrol from "../../assets/images/logo/avutura_logo_complete_darkpetrol.png";
import logo_pink from "../../assets/images/logo/avutura_logo_complete_pink.png";

class ContactOne extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Jetzt anfragen</h2>
                                <p className="description">Ich freue mich auf Ihre Anfrage. Gerne per Telefon <a href="tel:+4917680050386">0176-80050386</a> oder via E-Mail: 
                                    <a href="mailto:contact@avutura.de"> contact@avutura.de</a> </p>
                            </div>
                            
<ContactForm />
{/* <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Ihr Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Ihre E-Mail Adresse*"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Betreff"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Ihre Nachricht"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="mb_md--30 mb_sm--30 text-center">
                                <img src={logo_pink} alt="avutura" width={400}/>
                                <p>
                            <b>Avutura: developing talents – design your future<br /> <br /></b>
                            Bianca Wirtz<br /> 
                            Auf dem Döhn 8<br />
                            59069 Hamm<br /> 
                            <br />
                            E-Mail: 	contact@avutura.de<br />
                            Mobil: 	0176/80050386<br /> <br />
                          </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;
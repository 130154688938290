import React, { Component } from "react";

import icon1 from "../../assets/images/icons/analysis.png";
import icon2 from "../../assets/images/icons/recruitment.png";
import icon3 from "../../assets/images/icons/hiring.png";

const ServiceList = [
  {
    icon: icon1,
    title: "Analyse des Auftritts als Arbeitgeber",
    description:
    
    <ul>
      <p>Leistungen u.a.:</p>
      <li>Stellenanzeigen</li>
      <li>Auftritt in sozialen Medien [Instagram, LinkedIn, TikTok, Facebook]</li>
    </ul>
  },
  {
    icon: icon2,
    title: "Analyse des Recruiting-Prozesses",
    description:
     <ul>
      <p>Leistungen u.a.:</p>
      <li>Bewerbungswege</li> 
      <li>Art und Reaktionszeit der Rückmeldung</li> 
      <li>Aufbau und Durchführung der Gespräche</li> 
      <li>Rückmeldeprozess</li>
     </ul>
  },
  {
    icon: icon3,
    title: "Aufbauend auf der Analyse",
    description:
      <ul>
        <p>Leistungen u.a.:</p>
        <li>Individuelles Feedback und Entwicklung von Optimierungsideen, um noch mehr qualifizierte und motivierte Talente für sich zu gewinnen</li>
      </ul>
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img src={val.icon} alt="Avutura" />
                  {/* {val.icon} */}
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;

import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiGlobe, FiNavigation, FiUserCheck, FiUser, FiBook, FiBattery, FiCoffee, FiTrello } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiGlobe />,
        title: 'Unternehmensberatung',
        description: <ul>
            <li>Tages- und projektweise</li>
            <li>(Themenbezogen) fortlaufend</li>
            <li>Im Rahmen von Beratungspaketen (siehe unten)</li>
        </ul>
    },
    {
        icon: <FiLayers />,
        title: 'Keynotes, beispielhaft zu den Themen',
        description: 
        <ul>
            <li>Generationenübergreifendes Arbeiten</li>
            <li>Die Generation Z: Besonderheiten und Herausforderungen</li>
            <li>Der Lebenszyklus eines Mitarbeitenden („employee experience)</li>
            <li>Die Macht moderner Personalarbeit</li>
            <li>Modernes und erfolgversprechendes Recruiting</li>
            <li>Selbstwirksamkeit und Resilienz</li>
        </ul>
    },
    {
        icon: <FiUsers />,
        title: 'Moderation von Veranstaltungen, beispielhaft',
        description: 
        <ul>
            <li>Führungskräftetagungen</li>
            <li>Workshops</li>
            <li>Bildungsveranstaltungen</li>
        </ul>
    },
    { 
        icon: <FiNavigation />,
        title: 'Coachings im Rahmen von Unternehmen',
        description: 
        <ul>
            <li>Einzelcoaching</li>
            <li>Gruppencoaching</li>
            <li>Führungskräftecoaching</li>
        </ul>
    },
    {
        icon: <FiUserCheck />,
        title: 'Individuelles Coaching von Privatpersonen',
        description: 
        <ul>
            <li>Einmalig</li>
            <li>Kontinuierlich oder themenbezogen </li>
        </ul>
    },
    {
        icon: <FiTrello />,
        title: 'Karrierecoaching und Berufsorientierung',
        description: 
        <ul>
            <li>Einmalig</li>
            <li>Kontinuierlich oder themenbezogen</li>
        </ul>
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;

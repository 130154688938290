import React, { Component } from "react";

import brand1 from "../assets/images/brand/GFW-WAF_Ref.png";
import brand2 from "../assets/images/brand/NewLine_Group.png";
import brand3 from "../assets/images/brand/Competentia_NRW.png";
import brand4 from "../assets/images/brand/yourConfidence.png";
import brand5 from "../assets/images/brand/IHK_Dortmund.png";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand2} alt="Logo Images" />
          </li>
          {/* <li>
            <img src={brand3} alt="Logo Images" />
          </li> */}
          <li>
            <img src={brand4} alt="Logo Images" />
          </li>
          {/* <li>
            <img src={brand5} alt="Logo Images" />
          </li> */}
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;

import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BrandTwo from "../elements/BrandTwo";

import ServiceDetails from "../elements/ServiceDetails";
import Team from "../elements/Team";
import About from "../elements/About";
import Accordion from "../elements/Accordion";

import about from "../assets/images/about/Bianca_Wirtz.jpg";
import logo from "../assets/images/logo/logo-dark.png";
import logo_dark_petrol from "../assets/images/logo/avutura_logo_complete_darkpetrol.png";
import CallAction from "../elements/callaction/CallAction";
import ServiceOne from "../elements/service/ServiceOne";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Developing talents - design your future",
    title: "avutura",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const PortfolioLanding = () => {
  let title = "Wer ist avutura?",
    description = ''


  


const PostList = BlogContent.slice(0, 3)
  return (
    <div>
      <Helmet pageTitle="avutura: developing talents – design your future" />
      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div  
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        // <h1
                        //   className="title"
                        //   dangerouslySetInnerHTML={{ __html: value.title }}
                        // ></h1>
                        <img className="title" src={logo_dark_petrol} alt="About Images" width={300}/>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

            {/* Start Service Area  */}
            <div id="who" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--8">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">Was ist avutura?</h2>
                  <p>
                  „Avutura“ leitet sich aus dem Italienischen ab: „avere futuro“ = „eine Zukunft haben“ und steht für eine zukunftsorientierte Personalarbeit. 
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <p><br />
                  Bei „avutura“ geht es darum, Unternehmen, Führungskräfte und Mitarbeitende fit zu machen 
                  für die Herausforderungen und Ansprüche der Arbeitswelt von morgen: empathisch, pragmatisch 
                  und selbstwirksam. Denn vor dem Hintergrund der bekannten Herausforderungen (Fachkräftemangel, 
                  Generationenkonflikte, veränderte Ansprüche an die Arbeit(geber)) ist es für Arbeitgeber unerlässlich, sich mitzuentwickeln 
                  und so aufzustellen, dass sie diese Herausforderungen erfolgreich meistern. <br /><br />

                  Hierbei betrachte ich den gesamten Lebenszyklus eines Mitarbeitenden, die „employee experience“, entweder 
                  vollständig oder schwerpunktmäßig in den einzelnen Phasen. So kann es beispielsweise nur der Recruitingprozess,
                  das Onboarding oder auch die Personalstrategie der Mitarbeitenden-Entwicklung sein, die ich mir gemeinsam mit meinen Kunden anschaue. <br /><br />

                  Prinzipiell richte ich mich an alle Unternehmen, da die genannten Herausforderungen überall die gleichen sind. 
                  Im Speziellen unterstütze ich gerne die Unternehmen, die zu klein sind, um eine eigene Position für die Bearbeitung 
                  dieser Themen zu schaffen und nur punktuelle Unterstützung oder einen externen Blick auf die Dinge benötigen: 
                  klein- und mittelständische Unternehmen, Handwerksbetriebe, Gesundheitsdienstleister, aber auch IHKen und städtische Verwaltungen.
                  <br /><br />
                  Zu meinem Leistungsportfolio gehören (mehr unter <AnchorLink className="inline-link" href ="#service">was bietet avutura</AnchorLink>):
                  <ul>
                    <li>Unternehmensberatung</li>
                    <li>Keynotes</li>
                    <li>Moderation von Veranstaltungen</li>
                    <li>Coaching</li>
                  </ul> 
                  Meine Arbeit basiert auf der Annahme, dass die Menschen wieder mehr Selbstwirksamkeit spüren 
                  müssen, um langfristig resilient und leistungsbereit zu sein und somit zum Unternehmenserfolg beizutragen. 
                </p>
                <br /><br />
              </div>
            </div>
            <div className="center-align text-center">
            {/* <a className="rn-button-style--2 btn-solid" href="#contact"><span>Jetzt anfragen</span></a> */}
            <AnchorLink className="rn-button-style--2 btn-solid" href={`#contact`}>Jetzt anfragen</AnchorLink>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={about} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title text-center">{title}</h2>
                      <p className="description">{description}</p>
                      <p className="description">
                        Mein Name ist Bianca Wirtz und ich bin Diplom-Psychologin, Resilienztrainerin, Coach und pädagogische Beraterin und die Gründerin von avutura. <br /> <br /> 
                        Ich bin verheiratet, Mutter von drei Kindern und lebe nach Stationen in Münster, Heidelberg, Dortmund und Köln wieder in meinem Heimatort Hamm (Westf.). Neben meinen fachlichen Abschlüssen bringe ich umfassende Erfahrung in HR-Abteilungen verschiedener Unternehmen und Branchen, in der Hochschul- und Bildungslandschaft sowie bei NGOs mit. Zudem war ich mehrere Jahre direkt an Vorstand und Aufsichtsrat angegliedert und habe mit der Mitarbeitenden-Vertretung zusammengearbeitet und verhandelt. <br /> <br /> 
                        Neben den hauptberuflichen Stationen bin ich seit jeher im Ehrenamt tätig: als Vorstandsmitglied des Fördervereins der Kinderklinik Hamm, im Elternrat der Kita sowie als Klassen- und Schulpflegschaftsvorsitzende. <br /> <br /> 
                        
                        <h4>Was zeichnet mich als Beraterin, Vortragende, Moderatorin und Coach aus? </h4>
                        In meiner <AnchorLink className="inline-link" href={`#service`}><u>Beratung</u> </AnchorLink>sind mir drei Dinge stets besonders wichtig: Empathie, Pragmatismus und die Stärkung der Selbstwirksamkeit meiner Kunden. 
                        Es gibt keine langen Folienschlachten, träge Projektteams oder komplizierte, kostenintensive Prozesse. 
                        Mir geht es vielmehr darum, gemeinsam <b>schnelle</b> und <b>praxistaugliche</b> Lösungen zu erarbeiten, um Unternehmen (wieder) zu einem attraktiven Arbeitgeber zu machen, 
                        Führungskräfte und Mitarbeitende in ihren Rollen zu stärken und somit zu einem bestmöglichen Ergebnis beizutragen. Wir schauen uns den Status Quo an, besprechen die Zielsetzung und legen los!<br /> <br /> 
                        
                        Als <AnchorLink className="inline-link" href={`#service`}><u>Vortragende</u></AnchorLink> und <AnchorLink className="inline-link" href={`#service`}><u>Moderatorin</u> </AnchorLink>ist es mir wichtig, meine Zuhörenden mitzunehmen und Inhalte lebendig, praxisnah und authentisch zu gestalten. <br /><br />
                        Da ich nicht nur <AnchorLink className="inline-link" href={`#service`}><u>Coach</u></AnchorLink>, sondern auch Psychologin bin, kann ich Dinge ganzheitlicher betrachten und aufarbeiten. Zudem war ich jahrelang selbst in sehr verschiedenen Systemen tätig 
                        und kenne mich aus mit unternehmens- und machtpolitischen Besonderheiten und Konflikten. Resilienz betrachte ich als unerlässlich, um die Herausforderungen des (beruflichen) Lebens zu meistern und 
                        ermutige meine Klienten, ihre Komfortzone zu verlassen und auch mit Scheitern umzugehen, um daran zu wachsen und ihre persönliche Selbstwirksamkeit zu steigern.
                      </p>
                    </div>
                    {/* <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Service Area  */}
      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--8">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">Was bietet avutura?</h2>
                  <p>
                  Ich biete folgendes Portfolio an:
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-6 col-md-6 col-sm-6 col-12 text-left"
                />
                <p><br />
                  Ich biete meine Beratung zu Stunden- und Tagessätzen an, variierend von Umfang und Anforderungen. 
                  Private Coachingsessions werden stundenweise abgerechnet oder sind als 10er Paket buchbar. 
                  Bei Fragen zu Preisen und Angeboten melden Sie sich gern jederzeit.
<br /><br />
                </p>

              </div>
            </div>
            <div className="center-align text-center">
            <AnchorLink className="rn-button-style--2 btn-solid" href={`#contact`}><span>Jetzt anfragen</span></AnchorLink>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <section id="Page-Index" data-type="anchor">
      <div id="portfolio" className="fix" data-target="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div id="portfolio-test" className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Beispiel für ein Paket</h2>
                    <p>
                      "Bin ich ein attraktiver Arbeitgeber?" - Ein Checkup für Ihr Unternehmen
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                /> */}
                <ServiceOne />
                <h4>Preis:</h4>
                <p>Der Paketpreis (komplett) liegt je nach Umfang zwischen 1.000€ und 1.500€, jeweils zzgl. 19%.
Bei Implementierung in einen größeren Beratungsprozess ist selbstverständlich eine Verrechnung möglich.</p>


              </div>
              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>View More</span>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      </section>
      {/* End Portfolio Area */}

      {/* Start Blog Area */}
      <div id="blog" className="fix">
        <div className="rn-blog-area ptb--120 bg_color--8 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center">
                  <h2>Was macht avutura besonders?</h2>
                  <p>
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {/* {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={value.images}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
               <ServiceDetails />
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}

      {/* Start referenz Area  */}
      <div id="referenz" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--8">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">Referenzen</h2>
                  <p>
                  Umgesetzte Projekte und zufriedene Kunden
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <p>
                  <BrandTwo />
                 </p>
                <br /><br />
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* End referenz Area  */}
      

      {/* Start COntact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactOne />
        </div>
      </div>
      {/* End COntact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;

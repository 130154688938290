import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

//import serviceImg from "../assets/images/service/service-01.png";
import serviceImg from "../assets/images/service/600_605_Zielgruppe.png";
import serviceImg2 from "../assets/images/service/Herangehensweise_600.png";
import serviceImg3 from "../assets/images/service/Selbstwirksamkeit_600.png";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        {/* <PageHelmet pageTitle="Service Details" /> */}
        {/* End Pagehelmet  */}

        {/* <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        /> */}

        {/* Start Breadcrump Area */}
        {/* <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBSITE DEVELOPMENT</h2>
                  <p>Fresh From The Press Discription</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Die Zielgruppe</h4>
                          <p>
                            Avutura ist keine klassische Unternehmensberatung mit standardisierten Prozessen und einem 
                            riesigen Portfolio an Maßnahmen und Produkten. Avutura richtet sich vielmehr auch an die 
                            kleinen und mittelständischen Unternehmen sowie Handwerksbetriebe und öffentliche Arbeitgeber: 
                            an diejenigen, die oftmals zu klein für eigene HR-Verantwortliche sind und nur punktuelle Unterstützung 
                            benötigen, aber auf schnelle und unkomplizierte Unterstützung bauen. 
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80  align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Die Herangehensweise</h4>
                          <p>
                          Durch meinen besonders pragmatischen und lösungsorientierten Ansatz erarbeiten wir gemeinsam schnelle 
                          und kostensparende Lösungen und ich befähige meine Kunden, im Nachgang selbstständig die Dinge umzusetzen, die wir erarbeitet haben. Eine wiederkehrende und/oder kontinuierliche Unterstützung ist aber selbstverständlich möglich. 
                          Wir gehen direkt in die Zielsetzung, um die Herausforderungen des Status Quo zu meistern: oftmals sind es schon kleine Dinge, 
                          die Veränderungen bringen – der Anstoß und das TUN sind entscheidend. <br /><br />
                          Durch meinen breiten Erfahrungshintergrund in sehr unterschiedlichen Institutionen und Systemen und meine empathische, aber 
                          gleichzeitig auch fordernde Herangehensweise entsteht schnell ein Vertrauensverhältnis auf Augenhöhe: eine ideale Basis für eine erfolgreiche Zusammenarbeit.<br /><br />
                          Nicht zuletzt sind es mein Wissen über und meine jahrelange Erfahrung in der Zusammenarbeit mit jungen Menschen, die mich 
                          befähigen, im Bereich der generationenübergreifenden Zusammenarbeit zu beraten und zu moderieren: Vom Gegeneinander zum Miteinander!

                          </p>
                         
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg3}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title"><p>Ein besonderer Fokus: </p><br />Das Konzept der Selbstwirksamkeit</h4>
                          <p>
                          Selbstwirksamkeit ist ein besonderer Schwerpunkt meiner Arbeit.
                          Frei nach dem Psychologen Albert Bandura, ist Selbstwirksamkeit die Überzeugung einer Person, aufgrund eigener Kompetenzen auch schwierige Situationen erfolgreich bewältigen zu können“<br /><br />
                          Durch verschiedene Übungen und Herangehensweisen – auch in Coachings – stärke ich die Selbstwirksamkeit der Personen und/oder befähige Führungskräfte, 
                          einen Ansatz zu leben, der eben diese selbstwirksamkeitsstärkende Herangehensweise verfolgt. <br /><br />
                          Wenn die Mitarbeitenden und Führungskräfte mehr Selbstwirksamkeit spüren, sind sie motivierter, belastbarer und resilienter auch in stressigen Phasen. 
                          Das Ergebnis: mehr Arbeitsmotivation, weniger Fehlzeiten, weniger Fluktuation.

                          </p>
                          
                        </div>
                      </div>
                    </div>
  {/* End Single Area */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}
export default ServiceDetails;

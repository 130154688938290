import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Ihre Nachricht wurde erfolgreich versendet!</p>
    )
}

function ContactForm({props}) {
    const [ result,showresult ] = useState(false);
    const emailServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID
    const emailTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    const emailUserId = process.env.REACT_APP_EMAILJS_USER_ID


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            emailServiceId, 
            emailTemplateId, 
            e.target, 
            emailUserId
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Name"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Telefonnummer"
                required
                />
            </div>

            {/* <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Betreff"
                required
                />
            </div> */}
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder="Ihre Nachricht"
                required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">abschicken</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
